<template>
  <div
    id="users"
    class="page-container-table"
  >
    <button-all-header
      :contentBtnAdd="'Thêm tin tức'"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideExportFile="false"
      :hideDowload="false"
      :hideImportFile="false"
      @clickDelete="deleteItems"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @importFile="() => {}"
      @clickAdd="$router.push({name: 'news-management-create', params: {urlQuery}})"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:tiêu đề -->
        <span
          v-if="props.column.field === 'Title'"
          :id="'id_' + props.row.id"
          class="text-nowrap"
        >
          <span>{{ props.row.name }}</span>
          <b-popover
            :target="'id_' + props.row.id"
            triggers="hover"
            placement="top"
          >
            <template #title>{{ props.row.name }}</template>
            <div v-html="props.row.description"></div>
          </b-popover>
        </span>

        <!-- Column: người tạo-->
        <span v-else-if="props.column.field === 'Creator'">
          <span class="text-nowrap">{{ props.row.createdby }}</span>
        </span>

        <!-- Column: ngày tạo-->
        <span v-else-if="props.column.field === 'DateCreated'">
          <span class="text-nowrap">{{ props.row.createdDate | formatDateToDDMM }}</span>
        </span>

        <!-- Column: kiểu tin -->
        <span v-else-if="props.column.field === 'TypeNews'">
          <span class="text-nowrap">{{ props.row.groupNew }}</span>
        </span>
        <!-- Column:Trạng thái -->
        <div v-else-if="props.column.field === 'Status'">
          <span v-if="props.row.isUse === true">
            <feather-icon
              v-b-tooltip.hover.top="'Được sử dụng'"
              icon="CheckCircleIcon"
              size="18"
            />
          </span>
          <span v-else>
            <feather-icon
              v-b-tooltip.hover.top="'Không được sử dụng'"
              icon="XCircleIcon"
              size="18"
            />
          </span>
        </div>
        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            title="Chỉnh sửa"
            @click="$router.push({name: 'news-management-edit', params: {id: props.row.id}})"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="(pageNumber, pageSize)=> {
        urlQuery.pageNumber = pageNumber
        urlQuery.pageSize = pageSize
        fetchData(urlQuery)
      }"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="()=> {deleteAction()}"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  BPopover,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ConstantsApi from '../../constants/ConstantsApi'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BPopover,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    VBTooltip,
    ButtonAllHeader,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'TIÊU ĐỀ',
          field: 'Title',
          sortable: false,
        },
        {
          label: 'NGƯỜI TẠO',
          field: 'Creator',
          sortable: false,
        },
        {
          label: 'NGÀY TẠO',
          field: 'DateCreated',
          sortable: false,
        },
        {
          label: 'KIỂU TIN',
          field: 'TypeNews',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'Status',
          sortable: false,
        },
        {
          label: 'ActionFunction',
          field: 'ActionFunction',
          width: '150px',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      userId: '',
      dataList: [],
      totalRecord: 0,
    }
  },

  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.NEW_MANAGER_GET_PAGING, {
        params: urlQuery,
      })
      this.dataList = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.$hideLoading()
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.delete(ConstantsApi.NEW_MANAGER_DELETE, { params: { model: JSON.stringify(this.deleteIds) } }).then(res => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    // Lấy danh sách tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchData(this.urlQuery)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.popover{
    max-width: 60%; /* Max Width of the popover (depending on the container!) */
}
#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
